import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import GalleryPiece from "../../components/GalleryPiece";
// import UserTrending from "../../components/UserTrending";


const GalleryWidgetB = () => {
  const { palette } = useTheme();

  const galleryPieces = [
    {"model":"Midjourney3","picturePath":"thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-135101__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-143306__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_b9f8bb6e-a55f-4e17-be1d-b0dd8160f18e.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_c159f170-430b-4daa-b2e8-4cdf45a76e25.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_e58ea19f-8b50-4132-af02-98392135fd55.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_ef505be9-f50b-430f-80ab-8d77d81faea6.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_pho_3e189153-c0f4-430d-b65d-47c58e0f4bbb.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_pho_51e53497-b36a-4c51-98ac-a48da7b94099.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_3f4b24b1-c020-4419-816a-7832a2defbd1.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_b36ff62f-6a44-4c95-abdf-e3a8f3e21d24.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_bcc6082f-6406-43fc-ad09-e15320e85f63.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_fat-headded_de_2c3c4dc6-b6dd-4be2-b29e-351098d01d91.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_18c8dfae-7bcf-43fb-810b-235c1de1bfa8.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_2ca1b9a6-02ae-4035-be12-659671ff3e4b.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_4d7c9236-b1f7-407c-86be-69c453f583bf.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_4fe9a9ee-66f4-4715-b39c-0abdb82cdb7a.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00055_.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_700cfb9f-b8b1-4d7c-aaee-ca1a91e1acbd.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_82f61c3f-0542-4fee-9283-6c1e24f5b4f3.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_943cb0a1-63cc-4f38-a3e8-e6b6c2dfcf29.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-27-214621__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_9ec602de-efc1-417d-8e32-b66bd19163d0.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00060_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00082_.png"},
  ];

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        {/*Model: Midjourney3*/}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {galleryPieces.map((galleryPiece) => {
          console.log("= = = = GalleryWidgetB: " + JSON.stringify(galleryPiece));
          return(
          <GalleryPiece
            // key={galleryPiece._id}
            // userId={galleryPiece._id}
            // model={`${galleryPiece.model} ${galleryPiece.details}`}
            model={`${galleryPiece.model}`}  // Don't need the extra quote stuff. Leftovers.
            // subtitle={galleryPiece.occupation}
            // subtitle={galleryPiece.location}
            galleryPiecePath={galleryPiece.picturePath}
          />
          );
        }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default GalleryWidgetB;

