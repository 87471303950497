import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import GalleryPiece from "../../components/GalleryPiece";
// import UserTrending from "../../components/UserTrending";


const GalleryWidgetC = () => {
  const { palette } = useTheme();

const galleryPieces = [
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_photo-realistic_3d_rendering_of_a_hideous__4bd45f93-b781-4894-8eaa-0104b512e0d3.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_eb5a3bd0-eb9b-4971-9c27-cfc4785ddd66.png"},
    {"model":"DALL-E 2","picturePath":"DALLbizanmals.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_baae72e6-67ac-4204-a4ab-9beb5a04d401.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_92b7400f-5ec3-4f97-912b-15bcb0902ecd.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_35mm_photograph_of_a_massive_homeless_encampment__20360a4e-3868-473d-ab15-cfc0c7a9742a.png"}, {"model":"DALL-E 2","picturePath":"DALLantswaspss.png"},
    {"model":"DALL-E 2","picturePath":"DALLbugeyesdiscoball.png"},
    {"model":"DALL-E 2","picturePath":"DALLcityviolence.png"},
    {"model":"DALL-E 2","picturePath":"DALLfarm.png"},
    {"model":"DALL-E 2","picturePath":"DALLfordgalaxygrass.png"},
    {"model":"DALL-E 2","picturePath":"DALLfrog.png"},
    {"model":"DALL-E 2","picturePath":"DALLmoscow.png"},
    {"model":"DALL-E 2","picturePath":"DALLmosruins.png"},
    {"model":"DALL-E 2","picturePath":"DALLniceday.png"},
    {"model":"DALL-E 2","picturePath":"DALLopalglitterdemon.png"},
    {"model":"DALL-E 2","picturePath":"DALLopalskull.png"},
    {"model":"DALL-E 2","picturePath":"DALLovercrowd.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_ed_roth_painting_of_babies_in_prison._the__adfa0bbd-92ab-43bd-b6b3-9cea62d8a3df.png"},
    {"model":"DALL-E 2","picturePath":"DALLpicassostudio.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-27-212745__0.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-27-213440__0.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-28-004353__0.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-28-011334__0.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-135636__0.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00012_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00019_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00053_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00056_.png"},
];

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        {/*Model: Midjourney3*/}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {galleryPieces.map((galleryPiece) => {
          console.log("= = = = GalleryWidgetD: " + JSON.stringify(galleryPiece));
          return(
          <GalleryPiece
            // key={galleryPiece._id}
            // userId={galleryPiece._id}
            // model={`${galleryPiece.model} ${galleryPiece.details}`}
            model={`${galleryPiece.model}`}  // Don't need the extra quote stuff. Leftovers.
            // subtitle={galleryPiece.occupation}
            // subtitle={galleryPiece.location}
            galleryPiecePath={galleryPiece.picturePath}
          />
          );
        }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default GalleryWidgetC;

