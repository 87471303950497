// import { Box, useMediaQuery } from "@mui/material";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import Navbar from "../../scenes/navbar";
// import UsersTrendingWidgetFake from "../widgets/UsersTrendingWidgetFake";
import NavBanner from "../navBanner";
import GalleryWidgetA from "../widgets/GalleryWidgetA";
import GalleryWidgetB from "../widgets/GalleryWidgetB";
import GalleryWidgetC from "../widgets/GalleryWidgetC";
import GalleryWidgetD from "../widgets/GalleryWidgetD";


// TODO: Use isNonMobileScreens to make width 1 or 3 colummns. Render the whole layout one or the other switching
//   what large chunk of Box specs are returned. Other pages have examples of switching large chunks like that.
// flexBasis:
// https://webflow.com/glossary/flex-basis#:~:text=Flex%2Dbasis%20is%20a%20CSS,overflow%20on%20a%20web%20page.


const GalleryPage = () => {
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  // const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  // const getUser = async () => {
  //   const response = await fetch(`${Config.expressUri}/users/${userId}`, {
  //     method: "GET",
  //     // headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!user) return null;
  // if (!user) return <CircularProgress />;

  return (
    <Box>
      <NavBanner />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <Box m="2rem 0" />
          <GalleryWidgetA />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
            <GalleryWidgetB />
          </Box>
        )}
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
            <GalleryWidgetC />
          </Box>
        )}
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
            <GalleryWidgetD />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GalleryPage;

// ORIGINAL PAGE:
//   return (
//     <Box>
//       <Navbar />
//       <Box
//         width="100%"
//         padding="2rem 6%"
//         display={isNonMobileScreens ? "flex" : "block"}
//         gap="2rem"
//         justifyContent="center"
//       >
//         <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
//           <UserWidget userId={userId} picturePath={user.picturePath} />
//           <Box m="2rem 0" />
//           <FriendListWidget userId={userId} />
//         </Box>
//         <Box
//           flexBasis={isNonMobileScreens ? "42%" : undefined}
//           mt={isNonMobileScreens ? undefined : "2rem"}
//         >
//           <MyPostWidget picturePath={user.picturePath} />
//           <Box m="2rem 0" />
//           <PostsWidget userId={userId} isProfile />
//         </Box>
//       </Box>
//     </Box>
//   );
// };



// This is a NICE pattern for conditionally showing Boxes:
//         {isNonMobileScreens && (
//           <Box flexBasis="26%">
//             <AdvertWidget />
//             <Box m="2rem 0" />
//             <FriendListWidget userId={_id} />
//           </Box>
//         )}

