import { Box, useMediaQuery } from "@mui/material";
import { Helmet } from 'react-helmet';
// import { useSelector } from "react-redux";
import NavBanner from "../navBanner";
import GalleryWidgetA from "../widgets/GalleryWidgetA";
import GalleryWidgetB from "../widgets/GalleryWidgetB";
import GalleryWidgetC from "../widgets/GalleryWidgetC";
import GalleryWidgetD from "../widgets/GalleryWidgetD";


const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - AI Generated Video & Images - Resources for AI Developers</title>
        <meta name="description" content="AI Generated Video & Images - Resources for Machine Learning Developers" />
      </Helmet>
      <Box>
        <NavBanner />
        <Box
          width="100%"
          padding="2rem 6%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            <Box m="2rem 0" />
            <GalleryWidgetA />
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="2rem 0" />
              <GalleryWidgetB />
            </Box>
          )}
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="2rem 0" />
              <GalleryWidgetC />
            </Box>
          )}
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="2rem 0" />
              <GalleryWidgetD />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;

