import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import GalleryPiece from "../../components/GalleryPiece";
// import UserTrending from "../../components/UserTrending";


const GalleryWidgetC = () => {
  const { palette } = useTheme();

  const galleryPieces = [
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__8257b787-2e22-481f-95d2-907834bcee43.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_077e2401-78f9-4e56-a195-9bca5b684dae.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_c3bb1a4e-0994-46c3-9b32-e8ef90ab4f2d.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-28-004615__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_e55ca932-da53-4040-bd9b-60176f841d24.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_fb1a48d0-0d2c-4f7e-8fb1-1d0bb4a46db3.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_ff5a86ed-6c67-4ac3-9ea7-51b1bbdc51cb.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_5c904a2d-c528-4e3a-a837-57637a0612f6.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_b59f66f9-f469-49f5-972b-0b33abc802fc.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_glossy_296e264c-6d69-4cf8-a6bc-ff144a41fb76.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_glossy_93716696-bcda-40b8-b6db-81eeeb34ff25.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__6c1afaf0-bb3e-4b5d-a701-61d9e54b171a.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__b158ee0e-23a2-4a22-ab35-7896e37006d7.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_two_young_morbid_23211fa8-8e5a-4e08-bad4-1c948bae97d2.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvarod_dali_painting_of_a_glossy_ed_roth_4869c312-ab40-43b0-80b8-12bb94211dd2.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvarod_dali_painting_of_a_glossy_ed_roth_8d4ab021-cb1d-4616-bedc-a8cf9d7e15ed.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_02c9a37d-bb60-4322-bcff-e8ac1660f185.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6a6c688a-8137-478a-b54f-68c8ddc52ec1.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6e8a18e7-370c-46cd-a2f8-3514a629e522.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_75bf8d02-88f5-43f6-af53-4aa7fdb1683d.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvarod_dali_painting_of_100_gargoyles_which_loo_6e26cf39-a38e-442c-bf39-676de768ddb4.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_d7c9795e-b8b4-402d-8e91-88f26d5e0ce3.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_sentient_machine_thinking_about_the_definition_of_52482968-9ae6-4580-8bbf-9a7b494ec422.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-143348__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_sentient_machine_thinking_about_the_definition_of_6251b6a4-ac68-4d9e-8718-ba02e7c960a9.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-28-004257__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_sentient_machine_thinking_about_the_definition_of_a0f993a7-8563-42e5-9c19-da045a46b51f.png"},
  ];

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        {/*Model: Midjourney3*/}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {galleryPieces.map((galleryPiece) => {
            console.log("= = = = GalleryWidgetC: " + JSON.stringify(galleryPiece));
            return(
              <GalleryPiece
                // key={galleryPiece._id}
                // userId={galleryPiece._id}
                // model={`${galleryPiece.model} ${galleryPiece.details}`}
                model={`${galleryPiece.model}`}  // Don't need the extra quote stuff. Leftovers.
                // subtitle={galleryPiece.occupation}
                // subtitle={galleryPiece.location}
                galleryPiecePath={galleryPiece.picturePath}
              />
            );
          }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default GalleryWidgetC;

