import {Box, CircularProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBanner from "../navBanner";
/* --------------------------------- Wall ------------------------------- */
// import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import * as Config from "../../config";
import WidgetWrapper from "../../components/WidgetWrapper";


const WallPage = () => {
  const { palette } = useTheme();
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const galleryPieces = [
      {
        img: 'thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'FLUXfp8-2025-01-10-135101__0.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'FLUXfp8-2025-01-10-135101__0.png',
        title: 'FLUX1 Dev fp8',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'FLUXfp8-2025-01-10-135101__0.png',
        title: 'FLUX1 Dev fp8',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'FLUXfp8-2025-01-10-135101__0.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
      {
        img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png',
        title: 'Midjourney 3',
        author: '@jimmygizmo',
      },
  ]

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // return (
  //   <Box>
  //     <NavBanner />
  //     <Box
  //       width="100%"
  //       padding="2rem 6%"
  //       display={isNonMobileScreens ? "flex" : "block"}
  //       gap="2rem"
  //       justifyContent="center"
  //     >
  //
  //       <ImageList sx={{ width: "100%", height: "100%" }}>
  //         <ImageListItem key="Gallery" cols={4}>
  //           <ListSubheader component="div">Gallery</ListSubheader>
  //         </ImageListItem>
  //         {galleryPieces.map((item) => (
  //           <ImageListItem key={item.img}>
  //             <img
  //               srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
  //               src={`${item.img}?w=248&fit=crop&auto=format`}
  //               alt={item.title}
  //               loading="lazy"
  //             />
  //             <ImageListItemBar
  //               title={item.title}
  //               subtitle={item.author}
  //               actionIcon={
  //                 <IconButton
  //                   sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
  //                   aria-label={`info about ${item.title}`}
  //                 >
  //                   <InfoIcon />
  //                 </IconButton>
  //               }
  //             />
  //           </ImageListItem>
  //         ))}
  //       </ImageList>
  //
  //     </Box>
  //   </Box>
  // );


    return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Gallery
      </Typography>
        <Box display="flex" flexDirection="column" gap="1.5rem">
            <ImageList sx={{ width: "100%", height: "100%" }}>
              {galleryPieces.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    srcSet={`${Config.expressUri}/assets/${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${Config.expressUri}/assets/${item.img}?w=248&fit=crop&auto=format`}
                    // src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={<span>by: {item.author}</span>}
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
        </Box>
    </WidgetWrapper>
  );






};

export default WallPage;

