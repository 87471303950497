import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import GalleryPiece from "../../components/GalleryPiece";


const GalleryWidgetA = () => {
  const { palette } = useTheme();

  const galleryPieces = [
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_07574ce8-f596-426d-ab20-4b0156ed5f30.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_3b3977f8-e751-4982-8727-c7b7b17489a2.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-28-005622__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__92fea657-2072-470d-91d2-6065481d8b5d.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__a54d9be3-4d0f-4c16-bb8f-8358458668ce.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_5c02f156-ea23-40fd-ab11-3e7b388e3c80.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__bd3ff646-a7fe-4e3f-b3f9-1a9eb6928653.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_cinematic_photograph_from_an_architectural_digest_161f68a3-4c0e-48fe-bd3a-d00d6b68e6ca.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_clumpy_pile_of_oozing_opalescent_glossy_melting_g_72b5e0d4-4d4b-4729-9d26-a3599ac246a5.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_3d_rendering_of_the_salvador_dali_painting_4ac7b5be-b626-4aa6-8cc2-9983fa22079e.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_1db2f8a8-a36e-4623-9ab1-31906e827273.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_3cae9403-76d5-42d9-a6b6-f77f6ba3fe18.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_3f69ec43-45df-4986-aa98-3b2bc929d3ad.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_5e0c8100-2e8f-459c-b6b0-8a0fe4a72c8f.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_and_bright_Hieronymus_Bosch_painting_of_a__a11ba836-8c55-4ce3-a075-e5d02a33284c.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_ed_roth_painting_of_a_frail-loo_d14dc5c8-fb8f-4e11-bae8-a0bcc6c14194.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_07b00f85-5ce0-4abb-93b2-e1a43a8bda8e.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_149ec89f-8847-4843-b8d3-6b87d1333ce4.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_14d7d26b-360f-44c1-b374-52ed13ecea60.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_1fbbf06f-9891-4531-8708-5279b5bda2b2.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_4c3f0fb4-961e-463c-86d3-ebff2cb20fb0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_590c4c35-f991-4492-8515-65b785348f63.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_60eb2642-1992-461d-a5ee-418f8c775d1d.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-142515__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_64afd972-4578-4234-af7c-57fdbc1b5226.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_64eea1f5-0223-4b87-a04e-ad3ea004caf1.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-132236__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_7c55e887-cc9f-456c-9858-e0a21e0d39bc.png"},
  ];

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        {/*Model: Midjourney3*/}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {galleryPieces.map((galleryPiece) => {
            console.log("= = = = GalleryWidgetA: " + JSON.stringify(galleryPiece));
            return(
              <GalleryPiece
                // key={galleryPiece._id}
                // userId={galleryPiece._id}
                // model={`${galleryPiece.model} ${galleryPiece.details}`}
                model={`${galleryPiece.model}`}  // Don't need the extra quote stuff. Leftovers.
                // subtitle={galleryPiece.occupation}
                // subtitle={galleryPiece.location}
                galleryPiecePath={galleryPiece.picturePath}
              />
            );
          }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default GalleryWidgetA;

