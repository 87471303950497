import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(({ theme }) => ({
  padding: "1.5rem 1.5rem 0.75rem 1.5rem",
  backgroundColor: theme.palette.background.alt,
  borderRadius: "0.75rem",
}));

export default WidgetWrapper;


// ################################################################################################################

// CSS PADDING VALUES & POSITIONS

// ONE   "1.5rem"                       — First and only value: (ALL FOUR SIDES SAME PADDING).

// TWO   "1.5rem 1.5rem"                 — First value: (TOP and BOTTOM).    Second value: (RIGHT and LEFT).

// THREE "1.5rem 1.5rem 0.75rem"         — First value: TOP. Second value: (RIGHT and LEFT). Third value: BOTTOM
// FOUR  "1.5rem 1.5rem 0.75rem 1.5rem"  - Positions are for sides:    TOP,  RIGHT,  BOTTOM,  LEFT

// ################################################################################################################

// "1.5rem"  - All four sides are the same padding.

// "1.5rem 1.5rem"  - First value is for TOP and BOTTOM. Second value is for RIGHT and LEFT.

// "1.5rem 1.5rem 0.75rem"  - First value is for TOP. Second value is for RIGHT and LEFT. Third value is for BOTTOM.

// "1.5rem 1.5rem 0.75rem 1.5rem"  - Positions are for sides:    TOP,  RIGHT,  BOTTOM,  LEFT

// ################################################################################################################

