import { Box } from "@mui/material";
import * as Config  from "../config";


const GalleryPieceImage = ({ image, size = "60px" }) => {
  return (
    // <Box width={size} height={size}>
    <Box>
      {/*<img*/}
      {/*  style={{objectFit: "cover", borderRadius: "50%"}}*/}
      {/*  width={size}*/}
      {/*  height={size}*/}
      {/*  alt="user"*/}
      {/*  src={`${Config.expressUri}/assets/${image}`}*/}
      {/*/>*/}
      <img
        // style={{objectFit: "cover", borderRadius: "2%"}}
        style={{objectFit: "contain", width: "100%", height: "100%", borderRadius: "2%"}}
        alt="pic"
        src={`${Config.expressUri}/assets/${image}`}
      />
    </Box>
  );
};

export default GalleryPieceImage;

